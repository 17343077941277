<template>
    <div class="p-8 text-xs">
        <div class="rounded-2xl bg-white p-8">
            
            <div class="">
                        <div class="">
                            <span class="font-semibold text-base">Tambah Public Token</span>
                        </div>
                        <div class="mt-4 grid grid-cols-12 gap-4">
                       <!-- <div class="col-span-3">
                           <div class="flex justify-center border border-gray-200">
                               <div class="w-40 h-40 ">
                                   <img :src="default_url + 'default.png'" class="w-40 h-40 object-cover " @click="$refs.foto_profile.click()"/>

                               </div>
                           </div>
                       </div> -->
                       <div class="col-span-12">

                            <ValidationObserver v-slot="{ invalid}" ref="form" >
                            <form @submit.prevent="postData()" class="w-full" >
                            <div class="grid grid-cols-1 gap-4 ">
                                <ValidationProvider rules="required" v-slot="{ errors }" name="token" vid="token" class="grid grid-cols-12 gap-4 items-center">
                                <label for="" class="block mb-2 col-span-1">Token</label>  
                                <div class="col-span-11">
                                <input
                                    v-model="addData.token"
                                    type="text"
                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                    name="token"
                                    ref="token"
                                    id="token"
                                    />
                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                </div>
                                </ValidationProvider>
                            </div>
                            <div class="grid grid-cols-1 gap-4 mt-4">
                                <ValidationProvider rules="required" v-slot="{ errors }" name="expired" vid="expired" class="grid grid-cols-12 gap-4 items-center">
                                <label for="" class="block mb-2 col-span-1">Expired</label>  
                                <div class="col-span-11">
                                <input
                                    v-model="addData.expired"
                                    type="date"
                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                    name="expired"
                                    ref="expired"
                                    id="expired"
                                    />
                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                </div>
                                </ValidationProvider>
                            </div>
                            
                            <div class="grid grid-cols-1 gap-4 mt-4">
                                <ValidationProvider v-slot="{ errors }" name="Catatan" vid="note" class="grid grid-cols-12 gap-4 items-center">
                                <label for="" class="block mb-2 col-span-1">Perangkat</label>  
                                <div class="col-span-11">
                    
                                <v-select :options="device" v-model="addData.device_id" multiple :reduce="device => device.id" label="name" />

                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                </div>
                                </ValidationProvider>
                            </div>
                            
                            <div class="float-right">
                            <button
                                @click="$router.push('/publictoken')"
                                :class="`text-center py-2 px-8 rounded
                                
                                border border-gray
                                mr-2
                                hover:bg-gray-100
                                disabled:opacity-50
                                mt-4
                                
                                focus:outline-none my-1`"


                            >Batal</button>

                            <button
                                :disabled="invalid"
                                type="submit"
                                :class="`text-center py-2 px-8 rounded
                                text-white
                                hover:bg-blue-900 bg-blue-500
                                disabled:opacity-50
                                mt-4
                                
                                focus:outline-none my-1`"


                            >Simpan</button>
                            </div>

                            
                        </form>
                        </ValidationObserver>
                       </div>
                        </div>
                    
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapAction, mapActions} from 'vuex'
import { ValidationProvider,ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global'
import moment from 'moment'
export default {
    data: () => ({
        data: [],
        totalRows:100,
        perPage:10,
        limit:5,
        currentPage:1,
        disabled:false,
        modal_dialog:false,
        keyword:'',
        foto:'',
        device:[],
        roles:[],
        province:[],
        city:[],
        district:[],
        addData:{
            token:'',
            expired:'',
            device_id:[],

           
        },
        delete_id:'',
        modal_edit:false,
        default_url:process.env.VUE_APP_IMAGES
    }),
    mixins:[globalMixin],
    methods:{
        
       
        async postData(){
           

            this.axios.post('v1/publictoken',this.addData,this.config)
            .then((ress) => {
                this.$snack.success({
                    text: ress.data.message,

                })
                this.$router.push('/publictoken')
            })
            .catch(err => {
                if (err.response.status == 400) {
                        this.$refs.form.setErrors(err.response.data.message)
                }
            })
           
        },
       
       getDevice() {
           this.axios.get('v1/device',this.config)
            .then((ress) => {
                this.device = ress.data.rows
            })
       }
    
    },
    computed:{
      
    },
    created() {
        this.getDevice()
    },
    components:{
        ValidationProvider,ValidationObserver
    }
}
</script>